<template>
  <div class="view-body">
    <Container v-if="variable">
        <div class="form grid">
          <div class="tmp-col">
            <KeyValue
              label="Définition"
              :value="variable.variablepersonnalisee ? 'Variable personnalisée' : 'Variable prédéfinie'"
            />
          </div>
        </div>
      <Section>
        <div class="form grid">
          <div v-if="!variable.variable" class="tmp-col">
            <KeyValue label="Désignation" :value="variable.designation"/>
          </div>
          <div class="tmp-col" v-if="!variable.variable">
            <KeyValue label="Type de variable" :value="variable.type?.designation"/>
          </div>
          <div class="tmp-col" v-else>
            <KeyValue label="Variable" :value="variable.variable?.designation"/>
          </div>
        </div>
      </Section>
      <template v-if="!variable.variable">
        <Section v-show="variable.type?.uid === 'DECIMAL'">
          <div class="form grid">
            <div class="tmp-col">
              <KeyValue label="Valeur min." :value="variable.valeur_min"/>
            </div>
            <div class="tmp-col">
              <KeyValue label="Valeur max." :value="variable.valeur_max"/>
            </div>
            <div class="tmp-col">
              <KeyValue label="Unité" :value="variable.unite?.valeur"/>
            </div>
          </div>
        </Section>
        <Section v-show="variable.type?.uid === 'LIST' && repeatables?.valeurs">
          <div class="form grid">
            <div class="col--fullwidth">
              <KeyValue label="Valeurs">
                <RepeatableValues
                  :labels="['Designation']"
                  :values="repeatables?.valeurs"
                  :n-cols="1"
                />
              </KeyValue>
            </div>
          </div>
        </Section>
        <Section>
          <div class="form grid">
            <div class="tmp-col">
              <KeyValue label="Localisation de la notation"
                        :value="variable.laboratoire ? 'En laboratoire' : 'Sur le terrain'"/>
            </div>
          </div>
        </Section>
      </template>
      <Section class="no-padding-top">
        <div class="form grid">
          <div class="tmp-col">
            <KeyValue label="Cible affectée" :value="variable?.cible?.valeur"/>
          </div>
        </div>
      </Section>
    </Container>
  </div>
</template>

<script>

import Container from '@/components/layout/Container.vue'
import Section from '@/components/layout/Section.vue'
import KeyValue from '@/components/form/KeyValue.vue'
import RepeatableValues from '@/components/form/RepeatableValues.vue'

export default {
  name: 'VariableStudiedSettingsSubview',
  components: {
    KeyValue,
    RepeatableValues,
    Container,
    Section,
  },
  props: {
    pageTitle: {
      type: String,
    },
    task: {
      type: Object,
    },
    variableProps: {
      type: Object,
    },
    notation: {
      type: Object,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
    repeatables: {
      type: Object,
    },
    isEditable: {
      type: Boolean,
      default: true,
    },
  },

  emits: ['set-editable'],

  data(props) {
    return {
      variable: props.variableProps,
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
