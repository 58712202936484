<template>
  <Section class="section--no-padding">
    <Tabs
      v-model="variableTabs"
      layout="simple"
    >
      <Tab title="Définition">
        <VariableStudiedSettingsSubview
          v-if="fetched"
          :task="task"
          :variableProps="variable"
          :notation="notation"
          :repeatables="repeatables"
        />
      </Tab>
      <Tab title="Niveau de saisie">
        <VariableStudiedEntryLevelSubview
          v-if="fetched"
          :task="task"
          :variableProps="variable"
          :notation="notation"
          :repeatables="repeatables"
        />
      </Tab>
    </Tabs>
  </Section>

  <Teleport to="#view-header__teleport__single">
    <div class="view-header view-header--tasks-notations--edit">
      <div v-if="isEditable"
           class="view-header__header">
        <Btn text="Modifier la variable"
             color="primary"
             icon="create"
             :to="{
            name: `${$route.name}Edit`,
            params: {
              id: $route.params.id,
              tid: $route.params.tid,
              veid: $route.params.veid
            }
          }" />
      </div>
      <div class="view-header__footer">
        <Btn class="back-btn"
             round
             grow
             icon="arrow-left"
             :to="{
            name: 'protocolExperimentalTaskVariableStudiedList',
            params: {
              id: $route.params.id,
              tid: $route.params.tid
            }
          }" />
        <template v-if="task">
          <h1 class="page-title">
            {{ pageTitle }} - {{ variable.designation }}</h1>
        </template>
      </div>
    </div>
  </Teleport>

</template>

<script>

import Section from '@/components/layout/Section.vue'
import Tabs from '@/components/base/Tabs.vue'
import Tab from '@/components/base/Tab.vue'
import VariableStudiedSettingsSubview from
  '@/views/protocols/ExperimentalTask/SingleSubviews/Notation/VariableStudiedSettingsSubview.vue'
import VariableStudiedEntryLevelSubview from
  '@/views/protocols/ExperimentalTask/SingleSubviews/Notation/VariableStudiedEntryLevelSubview.vue'
import Btn from '@/components/base/Btn.vue'

export default {
  name: 'VariableStudiedSubview',
  components: {
    Section,
    Tabs,
    Tab,
    VariableStudiedSettingsSubview,
    VariableStudiedEntryLevelSubview,
    Btn,
  },
  props: {
    pageTitle: {
      type: String,
    },
    task: {
      type: Object,
    },
    isEditable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    const singleRoutes = [
      'protocolExperimentalTaskVariableStudiedSettings',
      'protocolExperimentalTaskVariableStudiedEntryLevel',
    ]

    const variableTabs = singleRoutes.findIndex((r) => r === this.$route.name)
    return {
      modal: {
        delete: false,
      },
      modalData: {},
      fetched: false,
      notation: {},
      singleRoutes,
      submit: false,
      variable: {},
      repeatables: {},
      variableTabs,
    }
  },

  emits: ['set-editable'],

  watch: {
    variableTabs() {
      this.$router.push({
        name: this.singleRoutes[this.variableTabs],
        params: {
          id: this.$route.params.id,
          tid: this.$route.params.tid,
          veid: this.$route.params.veid,
        },
      })
    },
  },

  mounted() {
    this.fetchVariable(this.$route.params)
  },

  methods: {
    fetchVariable(params) {
      this.fetchService.get(
        // eslint-disable-next-line
        `protocole/${this.$route.params.id}/evenement/${this.$route.params.tid}/variable/${params.veid}`,
      ).then(
        (variable) => {
          this.variable = variable.data
          if (this.variable.id) {
            const { id } = this.variable
            this.fetchService.get(
              // eslint-disable-next-line
              `protocole/${params.id}/evenement/${params.tid}/variable/${id}/organe`,
            ).then((valeurs) => {
              this.repeatables.organes = valeurs.data.map((value) => ({
                designation: value.designation,
                nb_echantillons: value.nb_echantillons,
              }))

              this.fetched = true
            })
          }
        },
      )
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
